import React, {useRef, useState, useEffect} from 'react'
import { Form } from "react-bootstrap"
import Col from "react-bootstrap/Col"
import DatePicker from "react-datepicker";
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";

const InputField = ({ startDate, setStartDate, fieldClass, labelClass, min, max, type, name, value, required, placeholder, handlechange, focusEvent, pattern, label, grpmd,step, defaultValue,  startTime, setStartTime }) => {
  const [isEditing, setEditing] = useState(false);
  const inputRef = useRef();
  useEffect(() => {
    $("input[name=telephone]").keypress(function (e) {
      //if the letter is not digit then display error and don't type anything
      if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
         //display error message
         $("#errmsg").html("Digits Only").show().fadeOut("slow");
                return false;
     }
    });
  });

  const toggleEditing = () => {
    setEditing(!isEditing);
    if (isEditing) {
      inputRef.current.focus();
    }
  };
  return (
  <Form.Group md={grpmd} className={"input-wrap "+`${type} ${step}`} controlId={"validation" + name}>
    <div className="custom-float">
      {(label && type !== 'hidden')
        ? <Form.Label className="form-label">{label}{required ? '*' : ''}</Form.Label>
        : ''
      }

      {type === 'datetime' ?

        <DatePicker
                selected={startDate}
                onChange={date => setStartDate(date)}
                showTimeSelect
                minDate={new Date()}
                placeholderText={placeholder}
                dateFormat="MMMM d, yyyy h:mm aa"
                className={fieldClass}
                required={required}
                onFocus={focusEvent}
                autocomplete="off"
                name={name}
                autoComplete={'' + Math.random()}                
                // onChange={handlechange}
                value={startDate}
                
              />
        : type === 'valuation_date' ? <>
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(date)}
            showTimeSelect={false}
            minDate={new Date()}
            placeholderText={placeholder}
            dateFormat="MMMM d, yyyy"
            timeFormat="hh:mm aa"
            className={fieldClass}
            required={required}
            autocomplete="off"
            name={name}
            autoComplete={'' + Math.random()}                
            // onChange={handlechange}
            value={startDate}
            //calendarIcon={<img src={CalendarIcon} />}      
          />
          {/* <img className="cal_icon" src={CalendarIcon} alt="Calender Icon" title="" /> */}
        </>
      : type === 'valuation_time' ? <>
          <DatePicker
            placeholderText={placeholder}
            timeFormat="hh:mm aa"
            className={fieldClass}
            required={required}
            autocomplete="off"
            name={name}
            autoComplete={'' + Math.random()}
            selected={startTime}
            onChange={(date) => setStartTime(date)}
            showTimeSelect
            showTimeSelectOnly
            timeCaption="Time"
            dateFormat="h:mm aa"
            value={startTime}
          /> 
          {/* <img className="cal_icon" src={CalendarIcon} alt="Calender Icon" title="" /> */}
        </>
      :
        <Form.Control
          className={fieldClass}
          required={required}
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={handlechange}
          pattern={pattern}
          onFocus={focusEvent}
          autocomplete="off"
          defaultValue={defaultValue || ``}
        />
      }
    </div>
  </Form.Group>
 );
};
export default InputField;