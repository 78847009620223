import axios from 'axios';
import { getToken, getUser } from '@myaccount/website-service'

export const branch_name = "DNG Estate Agents Team";
export const branch_address = "30 Leeson Park, Ranelagh, Dublin 6";

const baseURL = `${process.env.GATSBY_STRAPI_FORM_URL}`;
export const token = getToken() || process.env.GATSBY_STRAPI_FORM_TOKEN;
const my_account_url = "https://dng-strapi-myaccount.q.starberry.com";
const userData = getUser();

const authInstance = axios.create({
  baseURL: baseURL,
  headers: {
    Authorization: `Bearer ${token}`,
    // "Content-Type": "multipart/form-data"
  },
});

const authInstanceMyAccount = axios.create({
  baseURL: my_account_url,
  headers: {
    Authorization: `Bearer ${token}`,
    // "Content-Type": "multipart/form-data"
  },
});


export const postFileData = async (data) => {

  const res = await authInstance.post('upload', data)
  .then((res) => {
    // Success
    if (res.statusText === 'OK') {
      return {
        success: true,
        ...res.data,
      }
    }
    return { success: false }
  })
  .catch((error) => {
    // Failed
    if (error.response) {
      return {
        success: false,
        message: error.response.data,
      }
    } else {
      // Service error
    }
  })

  return res;

}

export const postFormData = async (data) => {

  let formData = JSON.parse(data.get('data'));

  if (userData?.uid) {
    formData['user_id'] = userData.uid;
    formData['contact_id'] = userData.contact_id;
    formData['authUser'] = true;
  }

  data.set('data', JSON.stringify(formData));

  const res = await authInstance.post('forms', data)
  .then((res) => {
    // Success
    if (res.statusText === 'OK') {
      return {
        success: true,
        ...res.data,
      }
    }
    return { success: false }
  })
  .catch((error) => {
    // Failed
    if (error.response) {
      return {
        success: false,
        message: error.response.data,
      }
    } else {
      // Service error
    }
  })

  return res;
}

export const postFormDataMyAccount = async (data) => {

    let formData = JSON.parse(data.get('data'));
    if (userData?.uid) {
      formData['user_id'] = userData.uid;
    }
    data.set('data', JSON.stringify(formData));
    
  const res = await authInstanceMyAccount.post('/stb-forms/forms', data)
  .then((res) => {
    // Success
    if (res.statusText === 'OK') {
      return {
        success: true,
        ...res.data,
      }
    }
    return { success: false }
  })
  .catch((error) => {
    // Failed
    if (error.response) {
      return {
        success: false,
        message: error.response.data,
      }
    } else {
      // Service error
    }
  })

  return res;
}
