import React from "react"
import { Button } from "react-bootstrap"
import Col from "react-bootstrap/Col"

const ButtonField = ({
  type,
  name,
  value,
  fieldClass,
  step,
  disabled,
  handlechange,
  handlechange2,
}) => (
  <div className={`btns ${step}`}>
    {step == "stepone" && (
      <Button
        onClick={handlechange}
        variant=""
        type={type}
        className={fieldClass}
      >
        {name}
      </Button>
    )}
    {step == "steptwo" && (
      <Button
        onClick={handlechange2}
        variant=""
        type={type}
        className={fieldClass}
      >
        {name}
      </Button>
    )}
    {step != "stepone" && step != "steptwo" && (
      <Button
        variant=""
        type={type}
        className={fieldClass}
        disabled={disabled ? disabled : null}
      >
        {name}
      </Button>
    )}
  </div>
)

export default ButtonField
