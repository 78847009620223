import React from "react"
import { Form } from "react-bootstrap"
import Col from "react-bootstrap/Col"

const SelectField = ({
  name,
  required,
  placeholder,
  values,
  handlechange,
  focusEvent,
  selected,
  fieldClass,
  label,
  grpmd,
  componentprops,
}) => (
  <Form.Group className="input-wrap" controlId={`validation${name}`}>
    {label ? (
      <Form.Label>
        {label}
        {required ? "*" : ""}
      </Form.Label>
    ) : (
      ""
    )}
    <Form.Control
      className={fieldClass}
      required={required}
      name={name}
      placeholderText={placeholder}
      onChange={handlechange}
      onFocus={focusEvent}
      as="select"
      value={componentprops ? componentprops[name] : ""}
    >
      {values.map(val => (
        <option value={val} key="" selected={val === selected ? true : false}>
          {val}
        </option>
      ))}
    </Form.Control>
  </Form.Group>
)

export default SelectField
